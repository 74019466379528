import React, { Component } from 'react'
import Header from '../components/Header';
import SheetkraftFooter from '../components/SheetkraftFooter.js';
import Fade from 'react-reveal/Slide';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';
import styles from './privacy.module.css';
import logo from '../../src/images/Logo.png'

export default class Privacy extends Component {
    render() {
        return (
            <div>
                < Helmet >
          
                    <meta name="description" property="og:description" content="SheetKraft builds high quality web-based applications using pre-designed powerful building blocks and connects to diverse sources of data across all your internal business applications"/>
                    <meta name="title" property="og:title" content="SheetKraft"/>
                    <meta name="image" property="og:image" content={logo}/>
                    <meta name="author" content="SheetKraft" />
                    < meta name = "keywords"
                    content = " automation, automation anywhere, uipath, rpa, robotic process automation, automate processes, robotic automation process, rpa services, rpa software, rpa tools, blue prismno code, rapid application development,business process automation" />
                    
                    <title>SheetKraft - Privacy</title>

                </Helmet>
                < Header />
                <Fade bottom>
                <div className="container ">
                    <div className="row">
                        < div className="col-lg-12 flex" >
                            <div className={styles.flex}>
                                <h2><strong>PRIVACY POLICY</strong></h2><hr/>
                                <h6><strong>This Privacy Policy applies to www.sheetkraft.com (Quantum Phinance Consulting Pvt. Ltd.)</strong> </h6>
                                <p>Quantum Phinance Consulting Pvt. Ltd. recognises the importance of maintaining your privacy. We value your privacy and appreciate your trust in us. This Policy describes how we treat user information we collect on http://www.sheetkraft.com and other offline sources. This Privacy Policy applies to current and former visitors to our website and to our online customers. By visiting and/or using our website, you agree to this Privacy Policy.</p><br/>
                                <h6><b>Information we collect:</b></h6>
                                <hr/>
                                <ul className={styles.ul}>
                                    <li><b>Contact information:</b> We might collect your name, email, mobile number, phone number, street, city, state, country and IP address. </li>
                                    <li><b>Information you post:</b> We collect information you post in a public space on our website or our social media channels. </li>
                                    <li><b>Other information:</b> If you use our website, we may collect information about your IP address and the browser you're using. We might look at what site you came from, duration of time spent on our website, pages accessed or what site you visit when you leave us. We might also collect the type of mobile device you are using, or the version of the operating system your computer or device is running.  </li>
                                    
                                </ul><br/>

                                <h6><b>We collect information in different ways:</b></h6>
                                <hr />
                                <ul className={styles.ul}>
                                    <li><b>We collect information directly from you: </b>We collect information directly from you when you register for call back on our website.</li>
                                </ul><br/>

                                <h6><b>Use of your personal information:</b></h6>
                                <hr />
                                <ul className={styles.ul}>
                                    <li><b>We use information to contact you: </b>We might use the information you provide to contact you for business website or for promotional purposes.</li>
                                    <li><b>We use information to respond to your requests or questions: </b>We might use your information to confirm your decision w.r.t closing the deal.</li>
                                    <li><b>We use information to improve our products and services: </b>We might use your information to customize your experience with us. This could include displaying content based upon your preferences.</li>
                                    <li><b>We use information to look at site trends and customer interests: </b>We may use your information to make our website and products better.</li>
                                    <li><b>We use information for security purposes: </b>We may use information to protect our company, our customers, or our websites.</li>
                                    <li><b>We use information for marketing purposes: </b>We might send you information about special promotions or offers. We might also tell you about new features or products. These might be our own offers or products. </li>
                                    <li><b>We use information to send you transactional communications: </b>We might send you emails or SMS about your account or offers.</li>
                                </ul>
                                
                                <p>We use information as otherwise permitted by law. </p><br />
                                <h6><b>Jurisdiction:</b></h6>
                                <hr/>
                                <p>If you choose to visit the website, your visit and any dispute over privacy is subject to this Policy and the website's terms of use. In addition to the foregoing, any disputes arising under this Policy shall be governed by the laws of India. </p>
                        
                            </div>
                        </div>
                
                    </div>

                    </div>
                    </Fade>
                < SheetkraftFooter/>
            </div>
        )
    }
}


