import React from 'react'
import sklogo from '../images/sk-logo.png'
import sklogosticky from '../images/sk-logo-sticky.png'
import { Navbar } from 'react-bootstrap'
import { Link } from 'gatsby'
import styles from "./Header.module.css"
// import { faBullhorn } from '@fortawesome/free-solid-svg-icons'


export default class Header extends React.Component {

    constructor(props) {
        super(props);
        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll, false);

    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll, false);

    }

    onScroll(e) {

        let x = document.getElementsByClassName("navbar")[0];
        let y = document.getElementsByClassName("sk-adaptive-header")[0];

        if (window.pageYOffset > 0) {
            x.classList.remove("navbar-dark");
            y.classList.add("sk-is-sticky");
        } else {

            x.classList.add("navbar-dark");
            y.classList.remove("sk-is-sticky");
        }

    }



    render() {

        let samePage = this.props.samePage;

        return (


            <div className={`container-fluid sk-adaptive-header ${styles.skAdaptiveHeader}`} >
                <Navbar collapseOnSelect expand="lg" variant="dark" className="container bg-pri-blue">
                    <Navbar.Brand >
                        <Link to="/">
                            <img className={`sk-logo ${styles.sklogo}`} src={sklogo} alt="sk-logo" />
                            <img className={`sk-logo-sticky ${styles.sklogo} `} src={sklogosticky} alt="sk-logo" />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        < ul className="nav navbar-nav mx-auto nav-fill w-100" >
                            < li className="nav-item productLink" >
                                <Link className="nav-link" activeClassName={"active"} to={samePage ? '#product' : '/#product'} >Product</Link>
                            </li>
                            < li className="nav-item" >
                                <Link className="nav-link" activeClassName={"active"} to={samePage ? '#team' : '/#team'}>Team</Link>
                            </li>
                            < li className="nav-item" >
                                <Link className="nav-link" activeClassName={"active"} to="/partners/">Partners</Link>
                            </li>
                            < li className="nav-item" >
                                <a className="nav-link" activeClassName={"active"} href="https://docs.sheetkraft.com/">Documentation</a>
                            </li>
                            < li className="nav-item" >
                                <Link className="nav-link" activeClassName={"active"} to="/blog/category/case-studies/">Case Studies</Link>
                            </li>
                            {/* < li className="nav-item" >
                                <Link className="nav-link" activeClassName={"active"} to="/blog/">Blog</Link>
                            </li> */}
                            {/* < li className="nav-item" >
                                <Link className="nav-link" activeClassName={"active"} to="/marketing.html">Marketing</Link>
                            </li> */}
                            < li className="nav-item" >
                                <Link className="nav-link" activeClassName={"active"} to={samePage ? '#contact' : '/#contact'}>Contact Us</Link>
                            </li>
                        </ul>
                    </Navbar.Collapse>
                </Navbar>

            </ div>


        )
    }

}
